export const VERSION = process.env.VERSION || '1';
export const PIPELINE_NAME = process.env.PIPELINE_NAME || 'react.hangikredi.com';
export const SERVER_CODE = process.env.SERVER_CODE || 'IISSRV07';
export type PageType = 'page' | 'about' | 'cek';

export enum DataLayerEventCategoryEnum {
  WIDGET_CLICK = 'Widget Click',
  QUERY = 'Sorgu',
}

export enum DataLayerPurchaseListName {
  CREDIT_CART_RECOURSE = 'Ticari Kredi Kartları',
  POST_PRODUCT_RECOURSE = 'Tavsiye Ettigimiz POS Urunleri',
  SME_RECOURSE = 'Tavsiye Ettigimiz KOBI Kredisi Urunleri',
  PROMOTION_RECOURSE = 'Maas Promosyonlari',
  CHEQUE_RECOURSE = 'Çek Gönder',
  MERCHANT_RECOURSE = 'Esnaf Kredisi',
  DOB_RECOURSE = 'Önerilen DOB Ürünleri',
  VEHICLE_RECOURSE = 'Önerilen Ticari Taşıt Kredileri',
}

export type EventAction = string | any;

export type DatalayerEventName =
  | 'PageDetails'
  | 'productClick'
  | 'purchase'
  | 'GAEvent'
  | 'login'
  | 'sign_up'
  | 'GAVirtual'
  | 'ecInternalPromotionClick'
  | 'ecInternalPromotionImpression';

export interface IDatalayerBase {
  event: DatalayerEventName;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  memberId?: string;
}

export interface Purchase extends IDatalayerBase {
  event: DatalayerEventName;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  ecommerce: Ecommerce;
}

export interface Slider extends IDatalayerBase {
  event: DatalayerEventName;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  ecommerce: EcommerceSlider | EcommerceSliderView;
}

export interface ActionFieldPurchase {
  id: string;
  revenue: string;
  action?: string;
  currency: string;
}

export interface ActionFieldSlider {
  id: string;
  name: string;
  creative?: string;
  position?: number | string;
}

export interface Ecommerce {
  purchase: EcommercePurchase;
}

export interface EcommerceSlider {
  promoClick: EcommerceSliderClick;
}

export interface EcommerceSliderView {
  promoView: EcommerceSliderClick;
}

export interface EcommercePurchase {
  actionField: ActionFieldPurchase;
  products: PurchaseProduct | any;
}

export interface EcommerceSliderClick {
  promotions: ActionFieldSlider[];
}

export interface PurchaseProduct {
  name?: string;
  id?: string;
  price?: string;
  brand?: string;
  category?: string;
  list?: string;
  position?: number | string;
  displayOrder?: number | string;
  quantity?: number | string;
  applyBanks?: {} | any;
  metrics?: {} | any;
  dimensions?: {} | any;
  variant?: {} | any;
  metric1?: number | string;
  metric2?: number | string;
  metric3?: number | string;
  metric4?: number | string;
  metric5?: number | string;
  metric6?: number | string;
  metric7?: number | string;
  metric8?: number | string;
  metric9?: number | string;
  dimension9?: string | number;
  dimension1?: string | number;
  dimension2?: string | number;
  dimension3?: string | number;
  dimension4?: string | number;
  dimension5?: string | number;
  dimension6?: string | number;
  dimension7?: string | number;
  dimension8?: string | number;
}

export interface SliderProduct {
  name?: string;
  id?: string;
  creative?: string;
  position?: number | string;
}

export type CustomRequestEventProps = {
  eventLabel: string;
  eventAction?: string;
  eventCategory: string;
  eventValue: string;
};

export interface IDatalayerPageDetails {
  event: DatalayerEventName;
  virtualPageUrl: string;
  virtualPageTitle: string;
  HK_pageCategory: string;
  HK_pageMidCategory: string;
  HK_pageSubCategory: string;
  HK_pageType: string;
  HK_pageName: string;
  HK_serverCode: string;
  HK_botStatus: string;
  version: string;
  pipelineName: string;
  loginState: boolean | false;
  hkMemberId?: string | 'NULL';
  hkMd5Email?: string | 'NULL';
  hkSha256Email?: string | 'NULL';
  step?: any;
}

export interface IDatalayerUserInfo {
  loginState: boolean;
}

export interface IDatalayerCustomEvent extends IDatalayerBase {
  eventValue?: string;
}

export interface PageDetails {
  pageData: any;
  clientData: any;
  type: PageType;
}
