'use client';
import type { ResponseWidgetDetail } from '@/src/interfaces/data-contracts';
import type { MouseEventHandler, ReactNode } from 'react';
import { InnerContainer } from '../../ui/common/inner-container';
import { ChevronRight } from '../../ui/icons/icons';
import Image from 'next/image';
import Link from 'next/link';
import { useDataLayer } from '@/src/hooks/use-datalayer';
import { useRouter } from 'next/navigation';
import { extractTextContent } from '@/src/utils/extract-text-content';

type Props = {
  notification: ResponseWidgetDetail;
};

function darken(hexColor: string, amount: number) {
  return hexColor.replace(/\w\w/g, (code) => {
    // Arka plan rengi ile baslik text rengini olusturmak icin
    const hex = (255 - Math.min(255, Math.round(((255 - parseInt(code, 16)) * (100 + amount)) / 100))).toString(16);
    return `${hex.length < 2 ? '0' : ''}${hex}`;
  });
}

export function NotificationItem({ notification }: Props): ReactNode {
  const { sendCustomRequestEvent } = useDataLayer();
  const router = useRouter();

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    sendCustomRequestEvent(
      location.href,
      `Notification Bar - ${extractTextContent(notification.description || '')}`,
      'CTA',
      ''
    );
    if (!notification.link) return;
    router.push(notification.link);
  };

  return (
    <Link href={notification.link || ''} onClick={handleClick} data-testid="notification">
      <div style={{ backgroundColor: notification.backgroundColor! }} className={`mt-[-1px] h-[42px]`}>
        <InnerContainer className="h-full">
          <div className="flex h-full items-center">
            <Image src={notification.imagePath || ''} height={16} width={16} alt="Duyuru" data-testid="icon" />
            <span
              style={{
                borderColor: darken(notification.backgroundColor!, 400),
                color: darken(notification.backgroundColor!, 1000),
              }}
              className="ml-[6px] mr-2 flex-shrink-0 border-r-[1px] border-solid pr-2 text-[12px] font-bold leading-[18px] md:ml-2 md:mr-5 md:pr-5"
              data-testid="title"
            >
              {notification.title}
            </span>
            <span
              className="mr-auto min-w-[0px] overflow-hidden text-ellipsis whitespace-nowrap text-[12px] font-semibold leading-[18px]"
              data-testid="description"
            >
              {extractTextContent(notification.description || '')}
            </span>
            <ChevronRight className="flex-shrink-0 md:hidden" height={18} width={18} />
          </div>
        </InnerContainer>
      </div>
    </Link>
  );
}
